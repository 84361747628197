import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { RecognitionResponse } from '@model/recognition-response.model';
import { BaseUrlService } from './url.service';

@Injectable()
export class RecognitionService extends BaseUrlService {
    constructor(public http: HttpClient, public router: Router) {
        super(router);
     }

    submitRecognition(recognition: Map<string, string>) {
        this.checkBaseUrl();
        const body = new FormData();
        recognition.forEach((data, key) => {            
            body.append(key, data);
        });        
        return this.http.post(`${this.instanseUrl}/mobileapp/submitOutlookRecognition.do`, body).pipe(map((response: any) => {
            const recognitionResponse = new RecognitionResponse();
            recognitionResponse.success = response.success;
            recognitionResponse.title = response.title;
            recognitionResponse.message = response.message;
            recognitionResponse.errorMessages = response.errorMessages;
            return recognitionResponse;
        }));
    }
}